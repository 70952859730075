export enum ErrorEnum {
  GENERIC = 'generic_error',
  CERTIFICATE_READ_ERROR = 'certificate_read_error',
  FILE_CORRUPTED = 'file_corrupted',
  MISSING_FILE_ON_REQUEST = 'missing_file_on_request',
  NOT_ALLOWED_FILE_EXTENSION = 'not_allowed_file_extension',
}

export const ERROR_MESSAGES_TRANSLATE = {
  generic_error: {
    description: 'Erro ao realizar assinatura',
    canRetry: true,
  },
  certificate_read_error: {
    description: 'Erro ao ler certificado',
    canRetry: true,
  },

  file_corrupted: {
    description: 'Arquivo corrompido',
    canRetry: false,
  },
  missing_file_on_request: {
    description: 'Arquivo enviado parece estar vazio',
    canRetry: false,
  },
  not_allowed_file_extension: {
    description: 'Arquivo não é um PDF',
    canRetry: false,
  },
};
